<template>
  <div class="schedule">
    <div class="time-ground">
      <ul>
        <li
          v-for="(time, key) in pageTimeGround"
          :key="key"
        >
          <span>{{ time }}</span>
          <p :style="timeListSty" />
        </li>
      </ul>
    </div>
    <div class="task-ground">
      <ul>
        <li
          v-for="(week, index) in weekGround"
          :key="index"
          class="task-list"
        >
          <p style="font-weight: bold; font-size: 18px">
            {{ week }}
          </p>
          <ul :style="taskListSty">
            <li
              v-for="(detail, key) in taskDetail[index]"
              :key="key"
              class="task-list-item"
              :style="detail.styleObj"
            >
              <p> <feather-icon
                size="22"
                class="mr-1"
                icon="ClockIcon"
                style="margin-right: 5px !important;"
              /> {{ detail.dateStart }} - {{ detail.dateEnd }}</p>
              <h4><feather-icon
                size="22"
                class="mr-1"
                icon="BookOpenIcon"
                style="margin-right: 10px !important;"
              />{{ detail.title }}</h4>
            </li>
          </ul>
        </li>
      </ul>
    </div>

  </div>
</template>

<style scoped>
.schedule{
  width: 90%;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}
.time-ground{
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.time-ground ul li{
  margin-top: 40px;
  font-size: 1rem;
  height: 5px;
}
.time-ground ul li span{
  position: absolute;
  left: -60px;
  transform: translateY(-50%);
}
.time-ground ul li p{
  position:absolute;
  left: 0;

  height: 1px;
  background-color: #EAEAEA;
}
.task-ground{
  width: 100%;
}
.task-list{
  float: left;
  width: 14%;
  box-sizing:border-box;
  border:1px solid #EAEAEA;
}
.task-list p{
  text-align: center;
  font-size: 1rem;
  padding: 5px;
}
.task-list-item{
  position: absolute;
  background-color: #577F92;
  width: 14%;
  height: 5px;
  cursor: pointer;
}
.task-list-item p{
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  border-bottom: 1px solid;
  margin-bottom: 5px;
  padding-bottom: 5px;
  color: white;
}
.task-list-item h4{
  color: white;
  margin: 5px;
}
</style>

<script>
export default {
  name: 'CustomSchedule',
  components: {
  },
  props: {
    timeGround: {
      type: Array,
      default: [],
    },
    weekGround: {
      type: Array,
      default: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
    },
    taskDetail: {
      type: Array,
      default: [],
    },
    color: {
      type: Array,
      default() {
        return [
          '#FF5733', '#4CAF50', '#9B59B6', '#3498DB', '#F1C40F', '#E74C3C', '#2C3E50',
        ]
      },
    },
    subjectIds: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      pageTimeGround: [],
      showModal: false,
      showModalDetail: {
        dateStart: '09:30',
        dateEnd: '10:30',
        title: 'Metting',
        week: 'Monday',
        styleObj: {
          backgroundColor: '#903749',
        },
        detail: 'Metting (German: Mettingen) is a commune in the Moselle department in Grand Est in north-eastern France.',
      },
      taskListSty: {
        height: '900px',
      },
      timeListSty: {
        width: '100%',
      },
      colorSubject: [],
    }
  },

  watch: {
    timeGround(value) {
      this.pageTimeGround = this.initTimeGroud(value)
      // return value;
    },
  },
  created() {
    this.pageTimeGround = this.initTimeGroud(this.timeGround)

    const maxTime = this.pageTimeGround[this.pageTimeGround.length - 1]
    const minTime = this.pageTimeGround[0]
    const maxMin = maxTime.split(':')[0] * 60 + maxTime.split(':')[1] * 1
    const minMin = minTime.split(':')[0] * 60 + minTime.split(':')[1] * 1

    for (let i = 0; i < this.subjectIds.length; i++) {
      const item = {
        subjectId: this.subjectIds[i],
        color: this.randomColor(),
      }
      this.colorSubject.push(item)
    }
    for (let i = 0; i < this.taskDetail.length; i++) {
      for (let j = 0; j < this.taskDetail[i].length; j++) {
        const startMin = this.taskDetail[i][j].dateStart.split(':')[0] * 60 + this.taskDetail[i][j].dateStart.split(':')[1] * 1
        const endMin = this.taskDetail[i][j].dateEnd.split(':')[0] * 60 + this.taskDetail[i][j].dateEnd.split(':')[1] * 1
        if (startMin < minMin || endMin > maxMin) {
          this.taskDetail[i].splice(j, 1)
          j--
          continue
        }
        const difMin = endMin - startMin
        const colorRd = this.colorSubject.find(item => item.subjectId == this.taskDetail[i][j].subjectId)
        const bgcl = 'background-color'
        this.taskDetail[i][j].styleObj = {
          // eslint-disable-next-line no-mixed-operators
          height: `${difMin * 45 / 60}px`,
          top: `${((startMin - (this.pageTimeGround[0].split(':')[0] * 60 + this.pageTimeGround[0].split(':')[1] * 1)) * 45 / 60) + 40}px`,
          // backgroundColor: this.color[~~(Math.random() * this.color.length)]
          [bgcl]: colorRd.color,
        }
        // this.taskDetail[i][j].styleObj[bgcl] = colorRd.color
      }
    }
  },
  mounted() {
    this.taskListSty.height = `${(this.pageTimeGround.length - 1) * 46.5}px`
    this.timeListSty.width = `${this.weekGround.length * 14}%`
    // console.log(this.taskDetail);
    // console.log(this.weekGround);
  },
  methods: {
    showDetail(obj, week) {
      obj.week = week
      this.showModalDetail = obj
      this.showModal = !this.showModal
    },
    initTimeGroud(value) {
      if (value && value.length == 2) {
        const startTime = value[0].split(':')[0] * 1
        const endTime = value[1].split(':')[0] * 1
        value = []
        for (let i = startTime; i <= endTime; i++) {
          // value.push()
          const hour = i < 10 ? `0${i}` : `${i}`
          value.push(`${hour}:00`)
        }
      } else {
        value = [
          '09:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
          '15:00',
          '16:00',
          '17:00',
          '18:00',
        ]
      }
      return value
    },

    generateRandomColor() {
      const maxVal = 0xFFFFFF
      let randomNumber = Math.random() * maxVal
      randomNumber = Math.floor(randomNumber)
      randomNumber = randomNumber.toString(16)
      const randColor = randomNumber.padStart(6, 0)
      return `#${randColor.toUpperCase()}`
    },
    randomColor() {
      return `#${Math.floor(Math.random()*16777215).toString(16)}`
    }
  },
}
</script>
